






















































































































import { Component, Vue } from 'vue-property-decorator';
import { Franchise, Office, WebsiteLevel } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';

@Component
export default class Footer extends Vue {
  private offices: Office[] = [];

  private office: Office | null = null;

  private franchise: Franchise | null = null;

  mounted() {
    this.loadOffices();
  }

  loadOffices(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:
        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.office = profile.Office;
        });

        break;
      case WebsiteLevel.Office:
        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      case WebsiteLevel.Franchise:
        API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
          this.offices = offices;
        });

        API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
          this.franchise = franchise;
        });

        break;
      default:
        Logger.Log(LogLevel.Error, 'Failed to load office for website. Website level is invalid.');

        break;
    }
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
